// extracted by mini-css-extract-plugin
export var component = "Header-module--component--oamST";
export var wrapper = "Header-module--wrapper--LOntA";
export var iconWrapper = "Header-module--icon-wrapper--38qk7";
export var icon = "Header-module--icon--pIYas";
export var logo = "Header-module--logo--rp1X8";
export var dropdownTrigger = "Header-module--dropdownTrigger--qAzUm";
export var dropdownIcon = "Header-module--dropdownIcon--1bzKQ";
export var dropdown = "Header-module--dropdown--Zwh1o";
export var dropdownList = "Header-module--dropdownList--7tpNO";
export var dropdownListItem = "Header-module--dropdownListItem--ffElS";
export var dropdownLink = "Header-module--dropdownLink--rpoiH";
export var dropdownOpen = "Header-module--dropdownOpen--W6PeS";