// extracted by mini-css-extract-plugin
export var component = "TopNavigation-module--component--RH+ZJ";
export var list = "TopNavigation-module--list--zMQ6Q";
export var item = "TopNavigation-module--item--HdAj4";
export var label = "TopNavigation-module--label--lEf3W";
export var text = "TopNavigation-module--text--5yTu1";
export var link = "TopNavigation-module--link--8Se1h";
export var iconWrapper = "TopNavigation-module--icon-wrapper--sN-Wc";
export var transparent = "TopNavigation-module--transparent--3C4pW";
export var iconWrapperHouseIcon = "TopNavigation-module--icon-wrapper-house-icon--Uuagf";
export var iconWrapperNewspaperIcon = "TopNavigation-module--icon-wrapper-newspaper-icon--7fOvW";
export var iconWrapperCalendarIcon = "TopNavigation-module--icon-wrapper-calendar-icon--cdDXO";
export var iconWrapperPeopleIcon = "TopNavigation-module--icon-wrapper-people-icon--UghdT";
export var icon = "TopNavigation-module--icon--sGhy8";