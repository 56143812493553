import SearchHit from "@whitespace/gatsby-plugin-search/src/components/SearchHit";
import SearchHitContact from "@whitespace/gatsby-plugin-search/src/components/SearchHitContact";
import * as defaultStyles from "@whitespace/gatsby-plugin-search/src/components/SearchHits.module.css";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

SearchHits.propTypes = {
  hits: PropTypes.array.isRequired,
};

export default function SearchHits({ styles = defaultStyles, hits }) {
  return (
    <ul className={styles.wrapper}>
      {hits.map((hit, index) => {
        const HitComponent =
          hit.contentType === "contact" ? SearchHitContact : SearchHit;
        return <HitComponent {...hit} key={index} />;
      })}
    </ul>
  );
}
