/* eslint-disable no-unused-vars */
/**
 * @shadow @whitespace/gatsby-theme-wordpress-basic/src/components/ArticleFeaturedImage.js
 * @reason HTML in captions where not not processed
 * @reason postSingleShowFeaturedImage was not honored
 */

import {
  Image,
  HTML,
} from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleFeaturedImage.module.css";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

ArticleFeaturedImage.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleFeaturedImage({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  let {
    contentNode: { featuredImage, displaySettings },
    // isPreview,
  } = usePageContext();

  featuredImage = displaySettings?.postSingleShowFeaturedImage !== false &&
    !!featuredImage?.node && {
      ...featuredImage.node,
      width: "1025",
      height: "288",
    };

  if (!featuredImage) {
    return null;
  }
  return null;
  // return (
  //   <Image
  //     className={clsx(styles.component, className)}
  //     {...featuredImage}
  //     caption={<HTML>{featuredImage.caption}</HTML>}
  //     {...restProps}
  //   />
  // );
}
