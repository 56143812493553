import Logo from "../components/Logo";
import "./Card.props.css";
import "./Grid.props.css";

export default {
  default: {
    components: {
      Logo,
    },
  },
  base: {
    lineHeight: {
      min: "1.25rem",
      prefered: "calc(2vw)",
      max: "1.75rem",
    },
    letterSpacing: "-0.5%",
    font: {
      family: "myriad-pro, sans-serif",
      min: "0.875rem",
      max: "1.25rem",
      prefered: "calc(0.5rem + 1vw)",
    },
  },
  box: {
    background: "gainsboro",
    // border: "",
    // borderRadius: "",
    // color: "",
    // fontSize: "",
    padding: "1rem",
  },
  brandColor: {
    themeVariant1: {
      default: "#456f87",
      text: "#ffffff",
    },
    themeVariant1Text: "#ffffff",
    themeVariant2: {
      default: "#f5f5f5",
      text: "#282A36",
    },
    themeVariant3: {
      default: "#164a69",
      text: "#ffffff",
    },
    themeVariant4: {
      default: "#c9e2ea",
      text: "#282A36",
    },
    themeVariant5: {
      default: "#dbded5",
      text: "#282A36",
    },
    themeVariant6: {
      default: "#c5dec6",
      text: "#282A36",
    },
    themeVariant7: {
      default: "#f0f2f0",
      text: "#282A36",
    },
    themeVariant8: {
      default: "#f7f5f1",
      text: "#282A36",
    },
    themeVariant9: {
      default: "#e3eadd",
      text: "#282A36",
    },
    themeVariant10: {
      default: "#e8efeb",
      text: "#282A36",
    },
  },
  breadcrumbs: {
    color: "#2c2c2c",
    colorActive: "#565656",
    fontWeight: 400,
    fontWeightActive: "normal",
    separatorMargin: "0.5rem",
    separatorColor: "#2c2c2c",
  },
  button: {
    default: {
      background: "#b7d6b9",
    },
    hover: {
      background: "#98A145",
    },
    focus: {
      boxShadow: "0 0 0 3px #0067A5",
    },
    current: {
      background: "var(--color-active)",
    },
  },
  displayFontFamily: "myriad-pro, sans-serif",
  color: {
    divider: "#C9E2EA",
    background: "#fff",
    foreground: {
      default: "#2C2C2C",
      inverse: "#fff",
    },
    active: {
      default: "#0070c9",
      foreground: "#fff",
    },
    theme: {
      blue: {
        background: "#1C4358",
        foreground: "#ffffff",
      },
      purple: {
        background: "#6C4C64",
        foreground: "#ffffff",
      },
      green: {
        background: "#98A145",
        foreground: "#2c2c2c",
      },
    },
    inactive: "#565656",
    interactive: "var(--color-active)",
    transparent: "transparent",
    surface: "#525c63",
    success: "#36b37e",
    warning: "#ffab00",
    info: "#0065ff",
    error: "#de350b",
    danger: "#de350b",
    brand: {
      color1: "#456f87",
      color2: "#f5f5f5",
      color3: "#164a69",
      color4: "#c9e2ea",
      color5: "#dbded5",
      color6: "#c5dec6",
      color7: "#f0f2f0",
      color8: "#f7f5f1",
      color9: "#e3eadd",
      color10: "#e8efeb",
    },
    blue: {
      default: 204,
      tint: {
        5: "hsla(var(--colorBlue), 84%, 31%, 10%)",
        4: "hsla(var(--color-blue), 84%, 31%, 20%)",
        1: "hsla(var(--color-blue), 84%, 31%, 80%)",
      },
      normal: "hsla(var(--color-blue), 84%, 31%, 100%)",
      shade: {
        1: "hsla(var(--color-blue), 84%, 25%, 100%)",
        4: "hsla(var(--color-blue), 81%, 6%, 100%)",
        5: "hsla(var(--color-blue), 81%, 3%, 100%)",
      },
    },
    green: {
      default: 90,
      tint: {
        5: "hsla(var(--color-green), 57%, 37%, 10%)",
        4: "hsla(var(--color-green), 57%, 37%, 20%)",
        1: "hsla(var(--color-green), 57%, 37%, 80%)",
      },
      normal: "hsla(var(--color-green), 57%, 37%, 100%)",
      shade: {
        1: "hsla(var(--color-green), 57%, 30%, 100%)",
        4: "hsla(var(--color-green), 58%, 7%, 100%)",
        5: "hsla(var(--color-green), 58%, 4%, 100%)",
      },
    },
    yellow: {
      default: 46,
      tint: {
        5: "hsla(var(--color-yellow), 100%, 47%, 10%)",
        4: "hsla(var(--color-yellow), 100%, 47%, 20%)",
        1: "hsla(var(--color-yellow), 100%, 47%, 80%)",
      },
      normal: "hsla(var(--color-yellow), 100%, 47%, 100%)",
      shade: {
        1: "hsla(var(--color-yellow), 100%, 38%, 100%)",
        4: "hsla(var(--color-yellow), 100%, 9%, 100%)",
        5: "hsla(var(--color-yellow), 100%, 5%, 100%)",
      },
    },
    orange: {
      default: 27,
      tint: {
        5: "hsla(var(--color-orange), 97%, 42%, 10%)",
        4: "hsla(var(--color-orange), 97%, 42%, 20%)",
        1: "hsla(var(--color-orange), 97%, 42%, 80%)",
      },
      normal: "hsla(var(--color-orange), 97%, 42%, 100%)",
      shade: {
        1: "hsla(var(--color-orange), 98%, 33%, 100%)",
        4: "hsla(var(--color-orange), 95%, 8%, 100%)",
        5: "hsla(var(--color-orange), 95%, 4%, 100%)",
      },
    },
    pink: {
      default: 340,
      tint: {
        5: "hsla(var(--color-pink), 97%, 35%, 10%)",
        4: "hsla(var(--color-pink), 97%, 35%, 20%)",
        1: "hsla(var(--color-pink), 97%, 35%, 80%)",
      },
      normal: "hsla(var(--color-pink), 97%, 35%, 100%)",
      shade: {
        1: "hsla(var(--color-pink), 97%, 27%, 100%)",
        4: "hsla(var(--color-pink), 94%, 7%, 100%)",
        5: "hsla(var(--color-pink), 94%, 3%, 100%)",
      },
    },
    neutral: {
      default: 204,
      tint: {
        5: "hsla(var(--color-neutral), 35%, 35%, 10%)",
        4: "hsla(var(--color-neutral), 35%, 35%, 20%)",
        2: "hsla(var(--color-neutral), 35%, 35%, 60%)",
        1: "hsla(var(--color-neutral), 35%, 35%, 80%)",
      },
      normal: "hsla(var(--color-neutral), 35%, 34%, 100%)",
      shade: {
        1: "hsla(var(--color-neutral), 35%, 28%, 100%)",
        2: "hsla(var(--color-neutral), 35%, 21%, 100%)",
        4: "hsla(var(--color-neutral), 37%, 7%, 100%)",
        5: "hsla(var(--color-neutral), 33%, 4%, 100%)",
      },
    },
  },
  focusBoxShadow: "0 0 0 3px var(--color-active)",
  gap: {
    min: "1rem",
    medium: "1.5rem",
    max: "2rem",
    default: "1.25rem",
  },
  logo: {
    foreground: "#b0bfc9",
    background: "#ebeff1",
    fontWeight: 300,
    fontSize: "1.25rem",
  },
  mainMaxWidth: "64rem",
  navigation: {
    activeBackground: "#0067A5",
    activeColor: "#fff",
    activeIconBackground: "#fff",
    activeIconColor: "#0067A5",
    activeTextDecoration: "none",
    currentBackground: "#0067A5",
    currentColor: "#fff",
    currentIconBackground: "#fff",
    currentIconColor: "#0067A5",
    defaultBackground: "transparent",
    defaultBorderRadius: "3px",
    defaultBoxShadow: "0 0 0 3px #0067A5",
    defaultColor: "#2c2c2c",
    defaultIconBackground: "#DADCD4",
    defaultIconColor: "#49667C",
    defaultPadding: "0.25rem 1rem",
    defaultTextDecoration: "none",
    expandedBackground: "#ddd",
    expandedColor: "#2c2c2c",
    hoverBackground: "#0067A5",
    hoverColor: "#fff",
    hoverIconColor: "#0067A5",
    hoverIconBackground: "#fff",
    hoverTextDecoration: "none",
  },
  menuIcon: "#4a4d59",
  searchForm: {
    color: "#000",
    placeholderColor: "#757575",
    background: "#fff",
    border: "#dfe1e4",
    spacing: "1rem",
    submitHoverBackgroundColor: "transparent",
    submitHoverColor: "inherit",
    focusBorder: "3px solid var(--color-active)",
    cancelBackground: "url(../../static/icons/close-circle--filled.svg)",
    clearFilterColor: "var(--color-foreground)",
    clearFilterTextDecoration: "underline",
    clearFilterHoverTextDecoration: "none",
    hitsLabelColor: "var(--color-foreground)",
    hitsLabelSpanColor: "var(--color-surface)",
  },
  searchHit: {
    borderColor: "var(--color-divider)",
    surface: "var(--color-foreground)",
  },
  searchHitContact: {
    borderColor: "var(--color-divider)",
    surface: "var(--color-foreground)",
  },
  selectField: {
    backgroundColor: "var(--color-green-tint-4)",
    color: "var(--color-foreground)",
    optionActiveBackgroundColor: "var(--color-active)",
    optionActiveColor: "var(--color-active-foreground)",
    optionColor: "var(--color-foreground)",
    optionHoverBackgroundColor: "var(--color-active)",
    optionHoverColor: "var(--color-active-foreground)",
    activeBackgroundColor: "var(--color-active)",
    activeColor: "var(--color-active-foreground)",
    dividerColor: "var(--color-divider)",
    dropdownColor: "var(--color-foreground)",
    dropdownHoverColor: "var(--color-active)",
    multiBackgroundColor: "var(--color-active)",
    multiColor: "var(--color-active-foreground)",
    multiCrossHoverBackgroundColor: "var(--color-active)",
    multiCrossColor: "var(--color-active-foreground)",
  },
  sidebar: {
    background: "#F0F2F0",
    link: "#282a30",
    hover: "#e3e6eb",
    icon: {
      wrapper: {
        background: "transparent",
        iconSize: "28px",
      },
    },
    z: {
      default: 101,
      overlay: 100,
    },
    headerHeight: "56px",
  },
  space: {
    4: "0.25rem",
    8: "0.5rem",
    12: "0.75rem",
    14: "0.875rem",
    16: "1rem",
    24: "1.5rem",
    32: "2rem",
    40: "2.5rem",
    48: "3rem",
    56: "3.5rem",
    64: "4rem",
  },
  toolbar: {
    background: "rgba(255, 255, 255, 0.8)",
    height: "56px",
  },
  toggleButton: {
    groupLabelActiveColor: "var(--color-active)",
  },
  treeMenu: {
    row: {
      margin: "0 -0.75rem",
      padding: "0.25rem",
    },
  },
};
